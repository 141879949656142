import React from "react";
import s from "./pages.module.scss";
import { Tab } from "../components/Tab";
import { ChartsHeader } from "../components/ChartsHeader";
import { useObserver } from "mobx-react-lite";
import { useStores } from "../hooks/useStores";
import { ChartLayout } from "./ChartLayout";

interface CrudPageProps {
  pageTitle: string;
  layout: string;
  children?: React.ReactNode;
}

//CRUD = Create, Read, Update, Delete

export const CrudPage = ({ pageTitle, layout }: CrudPageProps) => {
  const { filterStore, dataStore } = useStores();
  const { isYearly, isMonthly, setYearly, setMonthly } = filterStore;

  React.useEffect(() => {
    dataStore.refetchData();
  }, []);

  return useObserver(() => (
    <div className={s.wrapper}>
      <div className={s.tabs}>
        <Tab
          selected={isYearly()}
          title="Yearly"
          linkTo={`/${pageTitle}/yearly`}
          onClick={() => setYearly()}
        />
        <Tab
          selected={isMonthly()}
          title="Monthly"
          linkTo={`/${pageTitle}/monthly`}
          onClick={() => setMonthly()}
        />
      </div>
      <div className={s.chartsContainer}>
        <div className={s.chartsHeaderWrapper}>
          <ChartsHeader />
        </div>
        <ChartLayout layout={layout} pageTitle={pageTitle} />
      </div>
    </div>
  ));
};
