import React from "react";
import styleVars from "styles/_variables.scss";

const DropdownIcon = ({
  open,
  dark,
}: {
  open?: boolean;
  dark?: boolean;
}): JSX.Element => {
  const { textLightColor, textDarkColor } = styleVars;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        transform: open ? "rotate(180deg)" : "rotate(0)",
        transition: "0.1s",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 24.747 14.619"
      >
        <path
          d="M18,20.679l8.93-8.937a1.681,1.681,0,0,1,2.384,0,1.7,1.7,0,0,1,0,2.391L19.2,24.258a1.685,1.685,0,0,1-2.327.049L6.68,14.14a1.688,1.688,0,0,1,2.384-2.391Z"
          transform="translate(-5.688 -10.746)"
          stroke={dark ? textDarkColor : textLightColor}
          fill={dark ? textDarkColor : textLightColor}
          strokeWidth="1px"
        />
      </svg>
    </div>
  );
};

export default DropdownIcon;
