import React from "react";
import styleVars from "styles/_variables.scss";

interface DashboardIconProps {
  focus?: boolean;
  color?: string;
  focusColor?: string;
}

export const DashboardIcon = ({
  focus,
  color,
  focusColor,
}: DashboardIconProps) => {
  let fillColor = styleVars.textLightColor;

  if (color) {
    fillColor = color;
  }

  if (focus && focusColor) {
    fillColor = focusColor;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="15"
      viewBox="0 0 27 27"
    >
      <path
        d="M4.5,19.5h12V4.5H4.5Zm0,12h12v-9H4.5Zm15,0h12v-15h-12Zm0-27v9h12v-9Z"
        transform="translate(-4.5 -4.5)"
        fill={fillColor}
      />
    </svg>
  );
};
