import React from "react";
import s from "../SideMenu.module.scss";

export const DotIcon = ({
  color,
  focus,
}: {
  color?: string;
  focus?: boolean;
}) => {
  return (
    <div
      className={s.dotContainer}
      style={{ backgroundColor: focus ? "white" : "" }}
    >
      <div className={s.dot} style={{ backgroundColor: color ? color : "" }} />
    </div>
  );
};
