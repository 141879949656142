export enum MenuItemTypes {
  project = "project",
  projectCategory = "project_category",
}

export enum ProjectCategories {
  allProjects = "All projects",
  festivals = "Festivals",
  festival = "Festival",
  custom = "Custom",
}

export enum TimeScope {
  weekly = "weekly",
  monthly = "monthly",
  yearly = "yearly",
  daily = "daily",
}

export interface ICrudParams {
  year: number;
  month: number;
  project?: string;
  projectCategory?: string;
}

export interface IProjectMenuItem {
  value: string;
  label: string;
  type: MenuItemTypes;
}
