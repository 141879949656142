import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { initStore } from "./stores/Store";
import { StoreContext } from "./contexts/storeContext";

const store = initStore();

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <App />
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
