import React from "react";
import Chartjs, { ChartConfiguration } from "chart.js";
import { observer } from "mobx-react-lite";
import Chart from "chart.js";
import { Spinner } from "../../Spinner";
import { ChartData } from "../../../models/Data";

interface PieChartProps {
  data: ChartData;
  loading?: boolean;
}

export const PieChart = observer(({ data, loading }: PieChartProps) => {
  const dataset = data.datasets[0].dataset?.slice(0, 8);
  const labels = data.datasets[0].labels.slice(0, 8);

  const pieChartConfig = {
    type: "pie",
    data: {
      labels: labels,
      datasets: [
        {
          data: dataset,
          backgroundColor: data.datasets[0].colors,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 10,
          left: 40,
          right: 40,
          bottom: 40,
        },
      },
      legend: {
        position: "right",
        labels: {
          padding: 15,
          usePointStyle: true,
          font: {
            family: "Helvetica Neue",
          },
        },
      },
      plugins: {
        dataLabels: {
          formatter: (value: number, ctx: any) => {
            let sum = 0;
            let dataArr = ctx.chart.data.datasets[0].data;
            dataArr.map((data: any) => {
              sum += data;
            });
            let percentage = (value * 100) / sum + "%";
            return percentage;
          },
        },
      },
    },
  };

  const [chartInstance, setChartInstance] = React.useState<Chart>();
  const chartContainer = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(
        chartContainer.current,
        pieChartConfig as ChartConfiguration
      );
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  React.useEffect(() => {
    if (chartInstance) {
      chartInstance.config = Chart.helpers.extend(
        chartInstance?.config,
        pieChartConfig
      );
    }
    chartInstance?.update();
  }, [pieChartConfig]);

  return (
    <>
      {loading && <Spinner />}
      <canvas
        style={{ display: loading ? "none" : "block" }}
        ref={chartContainer}
      />
    </>
  );
});
