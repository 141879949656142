import React from "react";
import "styles/global.scss";
import { Layout } from "./components/layout";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CrudPage } from "./pages/crudPage";
import { CRUD, Layouts } from "./models/Data";
import { useStores } from "./hooks/useStores";

const Routes = () => {
  return (
    <>
      <Route exact path="/">
        <CrudPage pageTitle={"overview"} layout={Layouts.overview} />
      </Route>
      <Route path="/overview">
        <CrudPage pageTitle={"overview"} layout={Layouts.overview} />
      </Route>
      <Route path="/creates">
        <CrudPage pageTitle={CRUD.create} layout={Layouts.crud} />
      </Route>
      <Route path="/reads">
        <CrudPage pageTitle={CRUD.read} layout={Layouts.crud} />
      </Route>
      <Route path="/updates">
        <CrudPage pageTitle={CRUD.update} layout={Layouts.crud} />
      </Route>
      <Route path="/deletes">
        <CrudPage pageTitle={CRUD.delete} layout={Layouts.crud} />
      </Route>
    </>
  );
};

function App() {
  const { authStore } = useStores();

  React.useEffect(() => {
    if (window.location.search.includes("access_token")) {
      authStore.storeAuthTokenFromUrl();
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Layout>
          <Switch>
            <Routes />
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
