import { observer } from "mobx-react-lite";
import React from "react";
import { useStores } from "../../../hooks/useStores";
import { monthsArray } from "../../../utils/constants";
import { ArrowButton } from "../../ArrowButton";
import s from "./MonthSelect.module.scss";

interface MonthSelectProps {
  callback: () => void;
}

export const MonthSelect = observer(({ callback }: MonthSelectProps) => {
  const { filterStore } = useStores();
  const { setDate, incrementYear, decrementYear, year, date, setMonth } =
    filterStore;

  const getMonthNumber = (month: string) => {
    return monthsArray.findIndex((m) => m === month);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.yearSelect}>
        <ArrowButton onClick={decrementYear} direction="left" />
        <div className={s.year}>{date.year}</div>
        <ArrowButton onClick={incrementYear} direction="right" />
      </div>
      <div className={s.monthsGrid}>
        {monthsArray.map((m: string) => {
          return (
            <button
              className={monthsArray[date.month] === m ? s.selected : ""}
              onClick={() => {
                setDate(date.year, getMonthNumber(m));
                setMonth(m);
                callback();
              }}
            >
              {m.substring(0, 3)}
            </button>
          );
        })}
      </div>
    </div>
  );
});
