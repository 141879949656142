import { action, observable } from "mobx";

export default class AuthStore {
  @observable
  isAuthenticated = false; // TODO: implement route guard

  @action
  storeAuthTokenFromUrl = () => {
    const queryString = window.location.search;
    const token = queryString.split("access_token=")[1];
    localStorage.setItem("access_token", token);
  };
}
