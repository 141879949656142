import { observer } from "mobx-react-lite";
import React from "react";
import { LineChart, PieChart, TableChart } from "../../components/charts";
import { useStores } from "../../hooks/useStores";
import s from "../pages.module.scss";
import styleVars from "styles/_variables.scss";
import { relativizeData } from "../../utils/data";
import { CRUD } from "../../models/Data";
import { monthsArray } from "../../utils/constants";
import range from "../../utils/range";
import { getDaysInMonth } from "date-fns";

export const OverviewLayout = observer(() => {
  const { dataStore, filterStore } = useStores();

  const {
    createsColor,
    readsColor,
    updatesColor,
    deletesColor,
    textDarkColor,
  } = styleVars;

  return (
    <div className={s.chartsGrid}>
      <div className={s.chartContainer}>
        <LineChart
          xLabels={
            filterStore.isMonthly()
              ? range(
                  1,
                  getDaysInMonth(
                    new Date(filterStore.date.year, filterStore.date.month)
                  )
                )
              : monthsArray.map((m) => m.substring(0, 3))
          }
          loading={dataStore.fetchingCrud}
          data={{
            datasets: [
              {
                dataset: dataStore.chartData,
                labels: ["crud"],
                colors: [textDarkColor],
              },
            ],
          }}
        />
      </div>
      <div className={s.chartContainer}>
        <PieChart
          loading={dataStore.fetchingCrud}
          data={{
            datasets: [
              {
                labels: Object.values(CRUD),
                dataset: relativizeData(dataStore.crudData),
                colors: [createsColor, readsColor, updatesColor, deletesColor],
              },
            ],
          }}
        />
      </div>
      <div className={s.chartContainer}>
        <LineChart
          xLabels={
            filterStore.isMonthly()
              ? range(
                  1,
                  getDaysInMonth(
                    new Date(filterStore.date.year, filterStore.date.month)
                  )
                )
              : monthsArray.map((m) => m.substring(0, 3))
          }
          loading={dataStore.fetchingCrud}
          data={{
            xLabels: monthsArray.map((m) => m.substring(0, 3)),
            datasets: [
              {
                dataset: dataStore.createData,
                labels: ["create"],
                colors: [createsColor],
              },
              {
                dataset: dataStore.readData,
                labels: ["read"],
                colors: [readsColor],
              },
              {
                dataset: dataStore.updateData,
                labels: ["update"],
                colors: [updatesColor],
              },
              {
                dataset: dataStore.deleteData,
                labels: ["delete"],
                colors: [deletesColor],
              },
            ],
          }}
        />
      </div>
      <div className={s.chartContainer}>
        <TableChart
          labels={["project name", "operations", "operations %"]}
          loading={dataStore.fetchingCrud}
          data={dataStore.projectData}
        />
      </div>
    </div>
  );
});
