import { getDaysInMonth } from "date-fns";
import range from "./range";

export const getDateArray = (year: number, month: number) => {
  let paddedMonth: string | number = month;
  const daysInMonth = getDaysInMonth(new Date(year, month - 1));
  if (month < 10) {
    paddedMonth = String(month).padStart(2, "0");
  }
  return range(1, daysInMonth).map((i) => {
    let paddedDay: string | number = i;
    if (paddedDay < 10) {
      paddedDay = String(paddedDay).padStart(2, "0");
    }
    return `${year}-${paddedMonth}-${paddedDay}`;
  });
};
