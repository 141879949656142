import { observer } from "mobx-react-lite";
import React from "react";
import {
  LineChart,
  PieChart,
  BarChart,
  TableChart,
} from "../../components/charts";
import { useStores } from "../../hooks/useStores";
import s from "../pages.module.scss";
import styleVars from "styles/_variables.scss";
import {
  ContentTypeColors,
  CRUD,
  ProjectData,
  XYData,
} from "../../models/Data";
import { getDaysInMonth } from "date-fns";
import range from "../../utils/range";
import { monthsArray } from "../../utils/constants";
import { getMonthName } from "../../utils/getMonthName";

interface CrudLayoutProps {
  pageTitle: string;
}

export const CrudLayout = observer(({ pageTitle }: CrudLayoutProps) => {
  const { dataStore, filterStore } = useStores();

  const {
    createsColor,
    readsColor,
    updatesColor,
    deletesColor,
    textDarkColor,
  } = styleVars;

  let lineChartColor;
  let crudData = [];
  let contentTypeData: XYData[] = [];
  let projectData: ProjectData[] = [];
  switch (pageTitle) {
    case CRUD.create:
      lineChartColor = createsColor;
      crudData = dataStore.createData;
      contentTypeData = dataStore.createCTData;
      projectData = dataStore.projectCreateData;
      break;
    case CRUD.read:
      lineChartColor = readsColor;
      crudData = dataStore.readData;
      contentTypeData = dataStore.readCTData;
      projectData = dataStore.projectReadData;
      break;
    case CRUD.update:
      lineChartColor = updatesColor;
      crudData = dataStore.updateData;
      contentTypeData = dataStore.updateCTData;
      projectData = dataStore.projectUpdateData;
      break;
    case CRUD.delete:
      lineChartColor = deletesColor;
      crudData = dataStore.deleteData;
      contentTypeData = dataStore.deleteCTData;
      projectData = dataStore.projectDeleteData;
      break;
    default:
      lineChartColor = textDarkColor;
      crudData = dataStore.chartData;
      contentTypeData = [];
  }

  return (
    <div className={s.chartsGrid}>
      <div
        className={s.chartContainer}
        style={{
          gridColumn: 1,
          gridRow: 1,
        }}
      >
        <LineChart
          xLabels={
            filterStore.isMonthly()
              ? range(
                  1,
                  getDaysInMonth(
                    new Date(filterStore.date.year, filterStore.date.month)
                  )
                )
              : monthsArray.map((m) => m.substring(0, 3))
          }
          loading={dataStore.fetchingCrud}
          data={{
            datasets: [
              {
                dataset: crudData.map((dataset) => {
                  return { x: getMonthName(dataset.x), y: dataset.y };
                }),
                labels: [pageTitle],
                colors: [lineChartColor],
              },
            ],
          }}
        />
      </div>
      <div
        className={s.chartContainer}
        style={{
          gridColumn: 2,
          gridRow: 1,
        }}
      >
        <PieChart
          loading={dataStore.fetchingCrud}
          data={{
            datasets: [
              {
                labels: contentTypeData.map((ct) => ct.x) as string[],
                dataset: contentTypeData.map((ct) => ct.y),
                colors: contentTypeData.map((d) => ContentTypeColors[d.x]),
              },
            ],
          }}
        />
      </div>
      <div
        className={s.chartContainer}
        style={{
          gridColumn: 1,
          gridRow: 2,
        }}
      >
        <BarChart
          loading={dataStore.fetchingCrud}
          data={{
            datasets: [
              {
                labels: dataStore.contentTypes,
                dataset: contentTypeData,
                colors: contentTypeData.map((d) => ContentTypeColors[d.x]),
              },
            ],
          }}
        />
      </div>
      <div
        className={s.chartContainer}
        style={{
          gridColumn: 2,
          gridRow: 2,
        }}
      >
        <TableChart
          labels={["project name", pageTitle, `${pageTitle} %`]}
          loading={dataStore.fetchingCrud}
          data={projectData}
        />
      </div>
    </div>
  );
});
