import React from "react";
import s from "./ChartsHeader.module.scss";
import { DateSelect } from "../DateSelect";
import { ProjectSelect } from "../ProjectSelect";
import { WeekSelect } from "../WeekSelect";
import { useStores } from "../../hooks/useStores";

export const ChartsHeader = () => {
  const { filterStore } = useStores();
  const { timeScope } = filterStore;

  const renderDateSelect = () => {
    switch (timeScope) {
      case "yearly":
        return <DateSelect selectType="year">menu</DateSelect>;
      case "monthly":
        return <DateSelect selectType="month">menu</DateSelect>;
      case "weekly":
        return <WeekSelect>menu</WeekSelect>;
      case "daily":
        return <DateSelect selectType="day">menu</DateSelect>;
      default:
    }
  };

  return (
    <div className={s.chartsHeader}>
      <div className={s.firstColumn}></div>
      <div className={s.middleColumn}>{renderDateSelect()}</div>
      <div className={s.lastColumn}>
        <ProjectSelect />
      </div>
    </div>
  );
};
