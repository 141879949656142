import React from "react";
import Chartjs, { ChartConfiguration } from "chart.js";
import styleVars from "styles/_variables.scss";
import Chart from "chart.js";
import { Spinner } from "../../Spinner";
import { ChartData } from "../../../models/Data";

interface BarChartProps {
  data: ChartData;
  loading: boolean;
}

export const BarChart = ({ data, loading }: BarChartProps) => {
  const { crudColor } = styleVars;

  const { dataset, colors, labels } = data?.datasets[0];
  const barChartConfig = {
    type: "bar",
    data: {
      labels: labels,
      datasets: [
        {
          fill: false,
          data: dataset,
          borderColor: crudColor,
          label: "Number of operations per content category",
          backgroundColor: colors,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      layout: {
        padding: 20,
      },
      legend: {
        display: false,
      },
    },
  };

  const [chartInstance, setChartInstance] = React.useState<Chart>();
  const chartContainer = React.useRef<HTMLCanvasElement>(null);

  React.useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(
        chartContainer.current,
        barChartConfig as ChartConfiguration
      );
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  React.useEffect(() => {
    if (chartInstance) {
      chartInstance.config = Chart.helpers.extend(
        chartInstance?.config,
        barChartConfig
      );
    }
    chartInstance?.update();
  }, [barChartConfig]);

  return (
    <>
      {loading && <Spinner />}
      <canvas
        style={{ display: loading ? "none" : "block" }}
        ref={chartContainer}
      />
    </>
  );
};
