import React from "react";
import s from "./ListItem.module.scss";

interface ListItemProps {
  children?: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

export const ListItem = ({ children, selected, onClick }: ListItemProps) => {
  const classes = [s.item];

  if (selected) {
    classes.push(s.selected);
  }

  return (
    <li className={classes.join(" ")}>
      <button onClick={onClick} className={s.button}>
        {children}
      </button>
    </li>
  );
};
