import React from "react";
import { CrudLayout } from "./layouts/CrudLayout";
import { OverviewLayout } from "./layouts/OverviewLayout";

interface ChartLayoutProps {
  layout: string;
  pageTitle: string;
}

export const ChartLayout = ({ layout, pageTitle }: ChartLayoutProps) => {
  switch (layout) {
    case "overview":
      return <OverviewLayout />;
    case "crud":
      return <CrudLayout pageTitle={pageTitle} />;
    default:
      return <div />;
  }
};
