import React from "react";
import s from "./DateSelect.module.scss";
import ArrowIcon from "../../icons/ArrowIcon";
import { observer } from "mobx-react-lite";
import { monthsArray } from "../../utils/constants";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { YearSelect } from "./YearSelect";
import { MonthSelect } from "./MonthSelect";
import { useStores } from "../../hooks/useStores";

interface DateSelectProps {
  children: React.ReactNode;
  isOpen?: boolean;
  selectType: "year" | "month" | "week" | "day";
}

export const DateSelect = observer(({ selectType }: DateSelectProps) => {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const { filterStore } = useStores();
  const { date, setDate, isYearly } = filterStore;

  const incrementDate = () => {
    let newYear = date.year;
    let newMonth = date.month;

    if (isYearly()) {
      newYear++;
    } else {
      newMonth++;
      if (newMonth > 11) {
        newMonth = 0;
        newYear++;
      }
    }
    setDate(newYear, newMonth);
  };

  const selectedRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const decrementDate = () => {
    let newYear = date.year;
    let newMonth = date.month;

    if (isYearly()) {
      newYear--;
    } else {
      newMonth--;
      if (newMonth < 0) {
        newMonth = 11;
        newYear--;
      }
    }
    setDate(newYear, newMonth);
  };

  const handleClickOutside = (event: any) => {
    if (ref?.current === null) return;
    if (!ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const PickerSwitch = () => {
    switch (selectType) {
      case "year":
        return <YearSelect callback={() => setOpen(false)} />;
        break;
      case "month":
        return <MonthSelect callback={() => setOpen(false)} />;
      case "day":
        return <DateRangePicker ranges={[selectedRange]} />;
      default:
        return <div></div>;
    }
  };

  return (
    <div ref={ref}>
      <div className={s.wrapper}>
        <button onClick={() => decrementDate()} className={s.arrowLeftButton}>
          <ArrowIcon direction="left" />
        </button>
        <div className={s.select}>
          <button onClick={() => setOpen(!open)} className={s.selectButton}>
            <span>
              {!isYearly() && monthsArray[date.month] + " "}
              {date.year}
            </span>
          </button>
        </div>
        <button onClick={() => incrementDate()} className={s.arrowRightButton}>
          <ArrowIcon direction="right" />
        </button>
      </div>
      {open && (
        <div className={s.menuWrapper}>
          <div className={s.menu}>
            <PickerSwitch />
          </div>
        </div>
      )}
    </div>
  );
});
