import React from "react";
import { useStores } from "../../../hooks/useStores";
import range from "../../../utils/range";
import { ListItem } from "../../ListItem";
import s from "./year-select.module.scss";

interface YearSelectProps {
  callback: () => void;
}

export const YearSelect = ({ callback }: YearSelectProps) => {
  const currentYear = new Date().getFullYear();
  const fromYear = 2020; // First year with data
  const years = range(fromYear, currentYear);

  const { filterStore, dataStore } = useStores();
  const { setDate, date } = filterStore;

  const renderListItems = () => {
    return years.map((y) => (
      <ListItem
        onClick={() => {
          setDate(y, date.month);
          callback();
        }}
        selected={date.year === y}
      >
        {y}
      </ListItem>
    ));
  };

  return (
    <div className={s.wrapper}>
      <ul>{renderListItems()}</ul>
    </div>
  );
};
