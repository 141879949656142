import React from "react";
import s from "./SideMenu.module.scss";
import { Dropdown } from "../Dropdown";
import styleVars from "styles/_variables.scss";
import { MenuItem } from "./MenuItem";
import { DashboardIcon } from "./icons/DashboardIcon";
import { DotIcon } from "./icons/DotIcon";
import { useLocation } from "react-router-dom";

interface SideMenuProps {
  children?: any;
}

export const SideMenu = ({ children }: SideMenuProps) => {
  const currentPath = useLocation().pathname;
  const { createsColor, updatesColor, readsColor, deletesColor } = styleVars;

  return (
    <div className={`${s.wrapper}`}>
      <ul className={s.menu}>
        <Dropdown
          isOpen
          title={
            <div className={s.dropdownTitle}>
              <DashboardIcon />
              <span>Dashboard</span>
            </div>
          }
        >
          <MenuItem
            linkTo="/overview"
            selected={currentPath === "/" || /\/overview\/*/.test(currentPath)}
          >
            <DashboardIcon
              focus={currentPath === "/" || /\/overview\/*/.test(currentPath)}
              color={styleVars.textDarkColor}
              focusColor={styleVars.textLightColor}
            />
            <span className={s.title}>Overview</span>
          </MenuItem>
          <MenuItem
            linkTo="/creates"
            selected={/\/creates\/*/.test(currentPath)}
          >
            <DotIcon
              focus={/\/creates\/*/.test(currentPath)}
              color={createsColor}
            />
            <span className={s.title}>Creates</span>
          </MenuItem>
          <MenuItem linkTo="/reads" selected={/\/reads\/*/.test(currentPath)}>
            <DotIcon
              focus={/\/reads\/*/.test(currentPath)}
              color={readsColor}
            />
            <span className={s.title}>Reads</span>
          </MenuItem>
          <MenuItem
            linkTo="/updates"
            selected={/\/updates\/*/.test(currentPath)}
          >
            <DotIcon
              focus={/\/updates\/*/.test(currentPath)}
              color={updatesColor}
            />
            <span className={s.title}>Updates</span>
          </MenuItem>
          <MenuItem
            linkTo="/deletes"
            selected={/\/deletes\/*/.test(currentPath)}
          >
            <DotIcon
              focus={/\/deletes\/*/.test(currentPath)}
              color={deletesColor}
            />
            <span className={s.title}>Deletes</span>
          </MenuItem>
        </Dropdown>
      </ul>
    </div>
  );
};
