import React from "react";
import ArrowIcon from "../../icons/ArrowIcon";
import s from "./ArrowButton.module.scss";

interface ArrowButtonProps {
  direction: "up" | "right" | "down" | "left";
  onClick: () => void;
}

export const ArrowButton = ({ direction, onClick }: ArrowButtonProps) => {
  return (
    <button onClick={onClick} className={s.wrapper}>
      <ArrowIcon direction={direction} />
    </button>
  );
};
