import React from "react";
import s from "./layout.module.scss";
import { SideMenu } from "../SideMenu";

interface LayoutProps {
  children: any;
}

export const Layout = (props: LayoutProps) => {
  return (
    <div className={s.wrapper}>
      <SideMenu />
      <div className={s.content}>{props.children}</div>
    </div>
  );
};
