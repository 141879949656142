import { monthsArray } from "./constants";

export const getMonthName = (monthNumber: number | string) => {
  let index = monthNumber as number;
  if (typeof monthNumber === "string") {
    if (monthNumber.length > 2) {
      index = parseInt(monthNumber.split("-")[1]);
    } else {
      index = parseInt(monthNumber);
    }
  }
  return monthsArray[index - 1].substr(0, 3);
};
