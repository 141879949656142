import apisauce from "apisauce";
import { EventData, Project } from "../models/Data";
import { ICrudParams } from "../models/Filter";

console.log("api_url: ", process.env.REACT_APP_BACKEND_URL);

const headers = {
  authorization: localStorage.getItem("access_token"),
};
const client = apisauce.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/v1`,
  headers: headers,
});

// Helpers

export const getProjectQueryVars = (projects: (string | number)[]) => {
  return projects.map((p) => "project=" + p).join("&");
};

export const getProjectCategoryQueryVars = (projects: (string | number)[]) => {
  return projects.map((p) => "project_category=" + p).join("&");
};

//API calls

export const getCrud = async (params: ICrudParams, scope: string) => {
  const yearPathVar = `year=${params.year}`;
  const monthPathVar = `month=${params.month}`;
  const projectPathVar = `project=${params.project}`;
  const projectCategoryPathVar = `project_category=${params.projectCategory}`;

  let crudPath = "/crud?";
  crudPath = crudPath.concat(params.year ? yearPathVar : "");
  crudPath = crudPath.concat(
    scope === "monthly" && params.month ? "&" + monthPathVar : ""
  );
  crudPath = crudPath.concat(params.project ? "&" + projectPathVar : "");
  crudPath = crudPath.concat(
    params.projectCategory ? "&" + projectCategoryPathVar : ""
  );
  const data = await client.get<EventData[]>(crudPath);
  return data;
};

export const getProjects = async () => client.get<Project[]>(`/projects`);
