import { observable, action, computed } from "mobx";
import {
  IProjectMenuItem,
  MenuItemTypes,
  ProjectCategories,
  TimeScope,
} from "../models/Filter";
import { Store } from "./Store";

type ITimeInterval = { start: Date | null; end: Date | null };
export default class FilterStore {
  store: Store;

  constructor(store: Store) {
    this.store = store;
  }
  //Project filters

  @observable
  project?: any = undefined;

  @observable
  projectCategory?: any = undefined;

  @computed
  get projectSelection() {
    if (this.projectCategory) {
      return this.projectCategory.label;
    }
    if (this.project) {
      return this.project.label;
    }
    return ProjectCategories.allProjects;
  }

  @action
  updateProjectSelection = (item: IProjectMenuItem) => {
    switch (item.type) {
      case MenuItemTypes.project:
        this.project = {
          value: item.value,
          label: item.label,
        };
        this.projectCategory = undefined;
        break;
      case MenuItemTypes.projectCategory:
        this.project = undefined;
        if (item.value === ProjectCategories.allProjects) {
          this.projectCategory = undefined;
        } else {
          this.projectCategory = {
            value: item.value,
            label: item.label,
          };
        }
      default:
    }

    this.store.refetchData();
  };

  //Granularity filters

  d: Date = new Date();

  @observable
  date = {
    year: this.d.getFullYear(),
    month: this.d.getMonth(),
  };

  @observable
  timeInterval: ITimeInterval = {
    start: null,
    end: null,
  };

  @observable
  month: string = "";

  @observable
  year: string = "";

  @action
  incrementYear = () => {
    this.date.year = this.date.year + 1;
    this.store.refetchData();
  };

  @action
  decrementYear = () => {
    this.date.year = this.date.year - 1;
    this.store.refetchData();
  };

  //API

  @computed
  get crudParams() {
    return {
      year: this.date.year,
      month: this.date.month + 1,
      project: this.project?.value,
      projectCategory: this.projectCategory?.value,
    };
  }

  //Actions

  @action
  setMonth = (month: string) => {
    this.month = month;
  };

  @action
  setYear = (year: string) => {
    this.year = year;
  };

  @action
  setDate = (year: number, month?: number) => {
    const _month = month || month === 0 ? month : this.date.month;
    const start = new Date(year, _month, 1);
    const end = new Date(year, _month + 1, 0);

    this.timeInterval = { start, end };
    this.date.year = year;
    this.date.month = _month;

    this.store.refetchData();
  };

  @action
  updateDate = (year: number, month?: number) => {
    this.date.year = year;
    if (month || month === 0) {
      this.date.month = month;
    }
  };

  getTimeScopeFromPath = () => {
    const path = window.location.pathname;
    if (path.includes("yearly")) {
      return TimeScope.yearly;
    }
    if (path.includes("monthly")) {
      return TimeScope.monthly;
    }
    if (path.includes("weekly")) {
      return TimeScope.weekly;
    }
    return TimeScope.yearly;
  };

  @observable
  timeScope: string = this.getTimeScopeFromPath();

  @action
  setYearly = () => {
    this.timeScope = TimeScope.yearly;
    this.store.refetchData();
  };

  @action
  setMonthly = () => {
    this.timeScope = TimeScope.monthly;
    this.store.refetchData();
  };

  @action
  setWeekly = () => {
    this.timeScope = TimeScope.weekly;
  };

  @action
  setDaily = () => {
    this.timeScope = TimeScope.daily;
  };

  isYearly = () => {
    return this.timeScope === TimeScope.yearly;
  };

  isMonthly = () => {
    return this.timeScope === TimeScope.monthly;
  };

  isWeekly = () => {
    return this.timeScope === TimeScope.weekly;
  };

  isDaily = () => {
    return this.timeScope === TimeScope.daily;
  };
}
