import React from "react";
import s from "./WeekSelect.module.scss";
import DropdownIcon from "../Dropdown/DropdownIcon";
import { ListItem } from "../ListItem";
import { observer } from "mobx-react-lite";
import { useStores } from "../../hooks/useStores";

interface WeekSelectProps {
  children?: any;
}

export const WeekSelect = observer(({ children }: WeekSelectProps) => {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const { filterStore } = useStores();
  const { date, setDate } = filterStore;

  const years = [2021, 2020, 2019, 2018];

  const scrollHeight = ref.current?.scrollHeight;
  const clientHeight = ref.current?.clientHeight;
  const elementRect = ref.current?.getBoundingClientRect();

  let top = 0;
  let left = 0;

  if (scrollHeight && clientHeight && elementRect) {
    top = scrollHeight + elementRect.top;
    left = elementRect.left;
  }

  const position = { top, left };

  const renderListItems = () => {
    return years.map((y) => (
      <ListItem
        onClick={() => {
          setDate(date.month, y);
        }}
        selected={date.year === y}
      >
        {y}
      </ListItem>
    ));
  };

  return (
    <div className={`${s.wrapper}`}>
      <div className={s.label}>Weekly average of</div>
      <div className={s.buttonWrapper} ref={ref}>
        <button className={s.dropdownButton} onClick={() => setOpen(!open)}>
          {date.year}
        </button>
        <button className={s.iconButton} onClick={() => setOpen(!open)}>
          <DropdownIcon dark />
        </button>
      </div>
      {open && (
        <div className={s.menu} style={position}>
          <ul>{renderListItems()}</ul>
        </div>
      )}
    </div>
  );
});
