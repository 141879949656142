import styleVars from "styles/_variables.scss";

export interface Project {
  id: string;
  name: string;
}

export interface EventData {
  id: number;
  type: string;
  time_stamp: string;
  project: Project;
  category: string;
}

export interface ChartData {
  datasets: Dataset[];
  xLabels?: string[] | number[];
}

export interface Dataset {
  dataset: XYData[] | number[];
  labels: string[];
  colors?: string[];
}
export interface XYData {
  x: number | string;
  y: number;
}

export interface ApiCrudInput {
  year: number;
  month: number;
  project?: string;
  projectCategory?: string;
}

export enum CRUD {
  create = "creates",
  read = "reads",
  update = "updates",
  delete = "deletes",
}

export enum Layouts {
  crud = "crud",
  overview = "overview",
}

export enum ContentCategories {
  bookings = "bookings",
  stages = "stages",
  locations = "locations",
  personal = "personal",
  otherAreas = "other_areas",
  events = "events",
  volunteers = "volunteers",
  inventoryItems = "inventory_items",
  credentials = "credentials",
  inventoryAssignments = "inventory_assignments",
  vendor = "vendor",
  custom = "custom",
}

export const ContentTypeColors: { [key: string]: string } = {
  bookings: styleVars.bookingsColor,
  stages: styleVars.stagesColor,
  locations: styleVars.locationsColor,
  personal: styleVars.personalColor,
  other_areas: styleVars.otherAreasColor,
  events: styleVars.eventsColor,
  volunteers: styleVars.volunteersColor,
  inventory_items: styleVars.inventoryItemsColor,
  credentials: styleVars.credentialsColor,
  inventory_assignments: styleVars.inventoryAssignmentsColor,
  vendor: styleVars.vendorColor,
  custom: styleVars.customColor,
};

export interface ProjectData {
  id: string;
  data: { value: number; name: string };
}
