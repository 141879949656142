import React from "react";
import styleVars from "styles/_variables.scss";

const ArrowIcon = ({
  direction,
  color,
}: {
  direction: "up" | "right" | "down" | "left";
  color?: string;
}) => {
  return (
    <div
      style={{
        transform: `rotate(${
          direction === "up"
            ? "90deg"
            : direction === "right"
            ? "180deg"
            : direction === "down"
            ? "270deg"
            : direction === "left"
            ? "360deg"
            : "0"
        })`,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 30 30"
      >
        <path
          id="Icon_awesome-arrow-left"
          data-name="Icon awesome-arrow-left"
          d="M18.105,31.3l-1.561,1.561a1.681,1.681,0,0,1-2.384,0L.492,19.2a1.681,1.681,0,0,1,0-2.384L14.161,3.143a1.681,1.681,0,0,1,2.384,0L18.105,4.7a1.689,1.689,0,0,1-.028,2.412L9.6,15.188H29.813A1.683,1.683,0,0,1,31.5,16.875v2.25a1.683,1.683,0,0,1-1.687,1.688H9.6l8.473,8.072A1.677,1.677,0,0,1,18.105,31.3Z"
          transform="translate(0.003 -2.646)"
          fill={color ? color : styleVars.primaryColor}
        />
      </svg>
    </div>
  );
};

export default ArrowIcon;
