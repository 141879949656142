import React from "react";
import s from "../charts.module.scss";
import Chartjs, { ChartConfiguration } from "chart.js";
import styleVars from "styles/_variables.scss";
import { observer } from "mobx-react-lite";
import Chart from "chart.js";
import { Spinner } from "../../Spinner";
import { ChartData } from "../../../models/Data";

const { textDarkColor } = styleVars;

interface LineChartProps {
  data: ChartData;
  xLabels: (string | number)[];
  loading?: boolean;
}

export const LineChart = observer(
  ({ data, loading, xLabels }: LineChartProps) => {
    const datasets = data.datasets;

    const lineChartConfig = {
      type: "line",
      data: {
        labels: xLabels,
        datasets: datasets?.map((d) => {
          return {
            fill: false,
            data: d.dataset,
            borderColor: d.colors?.[0],
            label: d.labels[0],
            pointHitRadius: 20,
          };
        }),
      },
      options: {
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0,
          },
          point: {
            radius: 0,
            hoverRadius: 4,
          },
        },
        layout: {
          padding: {
            top: 10,
            left: 20,
            right: 20,
            bottom: 20,
          },
        },
        legend: {
          position: "top",
          labels: {
            boxWidth: 14,
            color: textDarkColor,
          },
        },
      },
    };
    const [chartInstance, setChartInstance] = React.useState<Chart>();
    const chartContainer = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
      if (chartContainer && chartContainer.current) {
        const newChartInstance = new Chartjs(
          chartContainer.current,
          lineChartConfig as ChartConfiguration
        );
        setChartInstance(newChartInstance);
      }
    }, [chartContainer]);

    React.useEffect(() => {
      if (chartInstance) {
        chartInstance.config = Chart.helpers.extend(
          chartInstance?.config,
          lineChartConfig
        );
      }
      chartInstance?.update();
    }, [lineChartConfig]);

    return (
      <>
        {loading && <Spinner />}
        <canvas
          style={{ display: loading ? "none" : "block" }}
          ref={chartContainer}
        />
      </>
    );
  }
);
