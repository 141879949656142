const types = ["CREATE", "READ", "UPDATE", "DELETE"];
export const relativizeData = (data: any) => {
  if (!data) {
    return data;
  }

  const countPerType: any = [];
  data.forEach((d: any) => {
    const type = d.type;
    countPerType[type] = countPerType[type] || 0;
    countPerType[type]++;
  });

  let relativeData = [];
  for (var i of types) {
    relativeData.push(countPerType[i]);
  }
  return relativeData;
};
