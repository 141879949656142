import React from "react";
import { Spinner } from "../../Spinner";
import s from "./TableChart.module.scss";

interface TableChartProps {
  data: { id: string; data: { value: number; name: string } }[];
  labels: string[];
  loading: boolean;
}

export const TableChart = ({ data, loading, labels }: TableChartProps) => {
  const makeRelative = (x: number, sum: number) => {
    const result = ((x / sum) * 100).toFixed(1);
    return result;
  };

  const renderRows = () => {
    const sum = data.reduce((a, b) => a + b.data.value, 0);
    return data.map((d, index) => (
      <tr key={index}>
        <td>{d.data.name}</td>
        <td>{d.data.value}</td>
        <td>{makeRelative(d.data.value, sum)} %</td>
      </tr>
    ));
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className={s.wrapper}>
          <table className={s.tableChart}>
            <thead className={s.boxShadow}>
              <tr>
                {labels.map((label) => (
                  <th>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>{renderRows()}</tbody>
          </table>
        </div>
      )}
    </>
  );
};
