import React from "react";
import s from "./SideMenu.module.scss";
import { Link } from "react-router-dom";

interface MenuItemProps {
  children?: React.ReactNode;
  selected?: boolean;
  linkTo: string;
  nested?: boolean;
}

export const MenuItem = ({
  selected,
  children,
  linkTo,
  nested,
}: MenuItemProps) => {
  return (
    <li className={`${s.menuItem}`}>
      <Link
        className={`${s.link} ${selected ? s.selected : ""}  ${
          nested ? s.nested : ""
        }`}
        to={linkTo}
      >
        {children}
      </Link>
    </li>
  );
};
