import DataStore from "./DataStore";
import FilterStore from "./FilterStore";
import UIStore from "./UIStore";
import AuthStore from "./AuthStore";

let store: Store | undefined;

export class Store {
  filterStore: FilterStore;
  dataStore: DataStore;
  uiStore: UIStore;
  authStore: AuthStore;

  constructor() {
    this.filterStore = new FilterStore(this);
    this.dataStore = new DataStore(this.filterStore);
    this.uiStore = new UIStore();
    this.authStore = new AuthStore();
  }

  refetchData = () => {
    this.dataStore.refetchData();
  };
}

export const initStore = () => {
  store = store != null ? store : new Store();
  return store;
};
