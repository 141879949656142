import { action, observable } from "mobx";
import { getCrud, getProjects } from "../api/ApiClient";
import {
  ContentCategories,
  EventData,
  Project,
  ProjectData,
  XYData,
} from "../models/Data";
import { ProjectCategories } from "../models/Filter";
import { paddedMonths } from "../utils/constants";
import { getDateArray } from "../utils/getDateArray";
import FilterStore from "./FilterStore";

export default class DataStore {
  filterStore: FilterStore;

  constructor(filterStore: FilterStore) {
    this.filterStore = filterStore;
  }

  @observable
  fetchingCrud: boolean = false;

  @observable
  fetchingProjects: boolean = false;

  @observable
  chartData: XYData[] = [];

  @observable
  crudData: EventData[] = [];

  @observable
  createData: XYData[] = [];

  @observable
  createCTData: XYData[] = [];

  @observable
  readData: XYData[] = [];

  @observable
  readCTData: XYData[] = [];

  @observable
  updateData: XYData[] = [];

  @observable
  updateCTData: XYData[] = [];

  @observable
  deleteData: XYData[] = [];

  @observable
  deleteCTData: XYData[] = [];

  @observable
  contentTypeData: XYData[] = [];

  @observable
  projectData: ProjectData[] = [];

  @observable
  projectCreateData: ProjectData[] = [];

  @observable
  projectReadData: ProjectData[] = [];

  @observable
  projectUpdateData: ProjectData[] = [];

  @observable
  projectDeleteData: ProjectData[] = [];

  @observable
  contentTypes: string[] = Object.values(ContentCategories);

  @observable
  projects: Project[] = [];

  @observable
  projectCategories: any = [
    {
      label: ProjectCategories.allProjects,
      value: ProjectCategories.allProjects,
    },
    {
      label: ProjectCategories.festivals,
      value: ProjectCategories.festival,
    },
    {
      label: ProjectCategories.custom,
      value: ProjectCategories.custom,
    },
  ];

  @action
  fetchProjects = async () => {
    this.fetchingProjects = true;
    const response = await getProjects();

    if (response?.ok && response.data) {
      this.projects = response.data;
    }
    this.fetchingProjects = false;
  };

  @action
  refetchData = async () => {
    await this.loadCrudData();
    await this.loadFilteredCrudData();
    await this.loadFilteredCTData();
    await this.loadProjectData();
  };

  @action
  loadCrudData = async () => {
    this.fetchingCrud = true;
    const response = await getCrud(
      this.filterStore.crudParams,
      this.filterStore.timeScope
    );
    if (response.ok && response.data) {
      this.crudData = response.data;
      this.chartData = this.countCrudData(this.crudData);
      this.contentTypeData = this.countContentTypeData(this.crudData);
    }
    this.fetchingCrud = false;
  };

  loadFilteredCrudData = (filter?: string) => {
    const crudData = this.crudData;
    let createData, readData, updateData, deleteData;

    if (crudData) {
      switch (filter) {
        case "create":
          createData = crudData.filter((d) => d.type === "CREATE");
          this.createData = this.countCrudData(createData);
          break;
        case "read":
          readData = crudData.filter((d) => d.type === "READ");
          this.readData = this.countCrudData(readData);
          break;
        case "update":
          updateData = crudData.filter((d) => d.type === "UPDATE");
          this.updateData = this.countCrudData(updateData);
          break;
        case "delete":
          deleteData = this.crudData.filter((d) => d.type === "DELETE");
          this.deleteData = this.countCrudData(deleteData);
          break;
        default:
          createData = crudData.filter((d) => d.type === "CREATE");
          readData = crudData.filter((d) => d.type === "READ");
          updateData = crudData.filter((d) => d.type === "UPDATE");
          deleteData = crudData.filter((d) => d.type === "DELETE");

          this.createData = this.countCrudData(createData);
          this.readData = this.countCrudData(readData);
          this.updateData = this.countCrudData(updateData);
          this.deleteData = this.countCrudData(deleteData);
      }
    } else {
      this.createData = [];
      this.readData = [];
      this.updateData = [];
      this.deleteData = [];
    }
  };

  loadFilteredCTData = (filter?: string) => {
    const crudData = this.crudData;
    let createData, readData, updateData, deleteData;

    if (crudData) {
      switch (filter) {
        case "create":
          createData = crudData.filter((d) => d.type === "CREATE");
          this.createCTData = this.countCrudData(createData);
        case "read":
          readData = crudData.filter((d) => d.type === "READ");
          this.readCTData = this.countCrudData(readData);
        case "update":
          updateData = crudData.filter((d) => d.type === "UPDATE");
          this.updateCTData = this.countCrudData(updateData);
        case "delete":
          deleteData = this.crudData.filter((d) => d.type === "DELETE");
          this.deleteCTData = this.countCrudData(deleteData);
        default:
          createData = crudData.filter((d) => d.type === "CREATE");
          readData = crudData.filter((d) => d.type === "READ");
          updateData = crudData.filter((d) => d.type === "UPDATE");
          deleteData = crudData.filter((d) => d.type === "DELETE");

          this.createCTData = this.countContentTypeData(createData);
          this.readCTData = this.countContentTypeData(readData);
          this.updateCTData = this.countContentTypeData(updateData);
          this.deleteCTData = this.countContentTypeData(deleteData);
      }
    } else {
      this.createCTData = [];
      this.readCTData = [];
      this.updateCTData = [];
      this.deleteCTData = [];
    }
  };

  loadProjectData = () => {
    const createData = this.crudData.filter((d) => d.type === "CREATE");
    const readData = this.crudData.filter((d) => d.type === "READ");
    const updateData = this.crudData.filter((d) => d.type === "UPDATE");
    const deleteData = this.crudData.filter((d) => d.type === "DELETE");

    this.projectCreateData = this.countProjectData(createData);
    this.projectReadData = this.countProjectData(readData);
    this.projectUpdateData = this.countProjectData(updateData);
    this.projectDeleteData = this.countProjectData(deleteData);

    this.projectData = this.countProjectData(this.crudData);
  };

  countProjectData = (fetchedData: EventData[]) => {
    const countPerProject: { [key: string]: { value: number; name: string } } =
      {};

    fetchedData.forEach((d) => {
      const { id, name } = d.project;
      countPerProject[id] = (countPerProject[id] && {
        value: countPerProject[id].value,
        name: name,
      }) || { value: 0, name: name };
      countPerProject[id] = {
        value: (countPerProject[id].value += 1),
        name: countPerProject[id].name,
      };
    });

    const countPerProjectArray = [];
    for (const [key, value] of Object.entries(countPerProject)) {
      countPerProjectArray.push({ id: key, data: value });
    }

    countPerProjectArray.sort((a, b) => b.data.value - a.data.value);

    return countPerProjectArray;
  };

  countContentTypeData = (fetchedData: EventData[]) => {
    let countPerType: { [key: string]: number } = {};

    fetchedData?.forEach((d) => {
      const category = d.category;
      if (this.contentTypes.includes(d.category)) {
        countPerType[category] = countPerType[category] || 0;
        countPerType[category]++;
      } else {
        countPerType[ContentCategories.custom] =
          countPerType[ContentCategories.custom] || 0;
        countPerType[ContentCategories.custom]++;
      }
    });
    let xyData: XYData[] = [];
    for (var i of this.contentTypes) {
      if (countPerType.hasOwnProperty(i)) {
        xyData.push({ x: i, y: countPerType[i] });
      } else {
        xyData.push({ x: i, y: 0 });
      }
    }

    xyData = xyData.sort((a, b) => {
      return b.y - a.y;
    });

    return xyData;
  };

  countCrudData = (fetchedData: EventData[]) => {
    let countPerType: { [key: string]: number } = {};
    let count, date;
    let dateArray: string[] = [];

    if (this.filterStore.isYearly()) {
      dateArray = paddedMonths;

      // Count per month
      fetchedData?.forEach((d) => {
        date = d.time_stamp.split("-")[1];
        countPerType[date] = countPerType[date] || 0;
        countPerType[date]++;
      });
    }
    if (this.filterStore.isMonthly()) {
      dateArray = getDateArray(
        this.filterStore.date.year,
        this.filterStore.date.month + 1
      );
      // Count per day
      fetchedData?.forEach((d) => {
        date = d.time_stamp.split("T")[0];
        countPerType[date] = countPerType[date] || 0;
        countPerType[date]++;
      });
    }
    // Fill missing dates
    let xyData: XYData[] = [];
    for (var i of dateArray) {
      if (countPerType.hasOwnProperty(i)) {
        xyData.push({ x: i, y: countPerType[i] });
      } else {
        xyData.push({ x: i, y: 0 });
      }
    }

    return xyData;
  };
}
