import React from "react";
import s from "./ProjectSelect.module.scss";
import DropdownIcon from "../Dropdown/DropdownIcon";
import { Project } from "../../models/Data";
import { useStores } from "../../hooks/useStores";
import { MenuItemTypes } from "../../models/Filter";
import { observer } from "mobx-react-lite";

interface ProjectSelectProps {}

export const ProjectSelect = observer(({}: ProjectSelectProps) => {
  const [open, setOpen] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const { filterStore, dataStore } = useStores();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const wrapperRef = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickOutside = (event: any) => {
    if (wrapperRef?.current === null) return;
    if (!wrapperRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  React.useEffect(() => {
    dataStore.fetchProjects();
  }, []);

  React.useEffect(() => {
    inputRef?.current?.focus();
  }, [open]);

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div ref={wrapperRef}>
      <div className={s.wrapper}>
        <div className={s.selection}>
          {open ? (
            <input
              value={searchQuery}
              placeholder={filterStore.projectSelection}
              ref={inputRef}
              className={`${s.selectionInput} ${open ? s.open : ""}`}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          ) : (
            <button onClick={() => handleClick()} className={s.selectionButton}>
              {filterStore.projectSelection}
            </button>
          )}
        </div>
        <button
          onClick={() => {
            handleClick();
          }}
          className={`${s.iconButton} ${open ? s.open : ""}`}
        >
          <div className={s.iconContainer}>
            <DropdownIcon open={open} dark />
          </div>
        </button>
      </div>
      {open && (
        <div className={s.menu}>
          <div className={s.menuTitle}>Show data from:</div>
          <ul className={s.categoryList}>
            {dataStore.projectCategories
              ?.filter((pc: any) =>
                pc.label.toLowerCase().includes(searchQuery)
              )
              .map((projCat: any) => {
                return (
                  <li
                    className={
                      filterStore.projectCategory?.value === projCat.value
                        ? s.selected
                        : ""
                    }
                    onClick={() => {
                      filterStore.updateProjectSelection({
                        value: projCat.value,
                        label: projCat.label,
                        type: MenuItemTypes.projectCategory,
                      });
                      setOpen(false);
                    }}
                  >
                    {projCat.label}
                  </li>
                );
              })}
          </ul>
          <hr className={s.divider} />
          <ul className={s.projectList}>
            {dataStore.projects
              ?.filter((p: Project) => p.name.includes(searchQuery))
              .map((project: Project) => {
                return (
                  <li
                    onClick={() => {
                      filterStore.updateProjectSelection({
                        value: project.id,
                        label: project.name,
                        type: MenuItemTypes.project,
                      });
                      setOpen(false);
                    }}
                  >
                    {project.name}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
});
