import React from "react";
import s from "./Tab.module.scss";
import { Link } from "react-router-dom";

interface TabsProps {
  selected?: boolean;
  title?: string;
  linkTo: string;
  onClick: () => void;
}

export const Tab = ({ selected, title, linkTo, onClick }: TabsProps) => {
  return (
    <Link
      to={linkTo}
      onClick={() => onClick()}
      className={`${s.tab} ${selected ? s.selected : ""}`}
    >
      {title}
    </Link>
  );
};
