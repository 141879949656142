import React from "react";
import s from "./Dropdown.module.scss";
import { useHistory } from "react-router-dom";
import DropdownIcon from "./DropdownIcon";

interface DropdownProps {
  title?: string | JSX.Element;
  children?: React.ReactNode;
  linkTo?: string;
  selected?: boolean;
  light?: boolean;
  noIcon?: boolean;
  isOpen?: boolean;
  absoluteMenu?: boolean;
}

export const Dropdown = ({
  children,
  title,
  linkTo,
  selected,
  light,
  noIcon,
  isOpen,
  absoluteMenu,
}: DropdownProps) => {
  const [open, setOpen] = React.useState(isOpen);
  const history = useHistory();

  const handleClick = () => {
    if (linkTo) {
      history.push(linkTo);
    }
    setOpen(!open);
  };

  return (
    <div className={`${s.wrapper}`}>
      <button
        onClick={() => handleClick()}
        className={`
        ${s.button} ${light ? s.light : ""}  
        ${selected ? s.selected : ""}
        `}
      >
        {title}
        {!noIcon && <DropdownIcon open={open} />}
      </button>
      <div className={`${absoluteMenu && s.absolute}`}>
        {open && <ul className={`${s.menu}`}>{children}</ul>}
      </div>
    </div>
  );
};
